import { useLocationMatch } from '@kaliber/routing'
import { useTranslate } from '/machinery/I18n'
import { CardVertical, CardVerticalReversed } from '/features/buildingBlocks/Card'
import { ImageCoverDynamicScale } from '/features/buildingBlocks/Image'
import { VideoWithContext } from '/features/buildingBlocks/Video'
import { CardWithoutImage } from '/features/pageOnly/lifeAtRituals/CardWithoutImage'
import { routeMap } from '/routeMap'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'
import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './GridMasonry.css'

export function GridMasonry({ items }) {
  const { videoAsFirstItem, colLeft, colRight } = useNormalizeColumns(items)

  return (
    <div className={styles.component}>
      {videoAsFirstItem &&
        <div className={styles.videoCell}>
          <Item
            key='video'
            type={videoAsFirstItem._type}
            item={videoAsFirstItem}
            side='left'
          />
        </div>
      }
      <Column items={colLeft} isLeft layoutClassName={styles.columnLeftLayout} />
      {Boolean(colRight.length) && <Column items={colRight} layoutClassName={styles.columnRightLayout} />}
    </div>
  )
}

function Column({ items, isLeft = undefined, layoutClassName = undefined }) {
  const side = isLeft ? 'left' : 'right'

  return (
    <div className={cx(styles.componentColumn, layoutClassName)}>
      {items?.map((x, i) => {
        return (
          <Item
            key={i}
            type={x.item._type}
            item={x.item}
            info={x.info}
            {...{ side }}
          />
        )
      })}
    </div>
  )
}

function useNormalizeColumns(allItems) {
  const [...items] = allItems
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false

  const articleInColCountRef = React.useRef(0)
  const setArticleInColCount = React.useCallback(handleArticleInColCount, [])

  const videoAsFirstItem = React.useMemo(() => isVideo(items[0]) ? items.shift() : null, [items])

  const colLeft = React.useMemo(() => {
    const leftColItems = isMobile
      ? items.filter(item => !isImage(item))
      : items.filter((item, i) => i % 2 === 0)
    const leftColItemObjects = createColItemObjects({ items: leftColItems, isLeftCol: true, articleInColCount: articleInColCountRef?.current, setArticleInColCount })

    return leftColItemObjects
  }, [items, setArticleInColCount, isMobile])

  const colRight = React.useMemo(() => {
    const rightColItems = isMobile
      ? []
      : items.filter((item, i) => i % 2 === 1)

    const rightColItemObjects = createColItemObjects({ items: rightColItems, isLeftCol: false, articleInColCount: articleInColCountRef?.current, setArticleInColCount })
    return rightColItemObjects
  }, [items, setArticleInColCount, isMobile])

  return {
    videoAsFirstItem,
    colLeft,
    colRight
  }

  /** @param {number} count   */
  function handleArticleInColCount(count) {
    articleInColCountRef.current = count
  }
}

function createColItemObjects({ items, isLeftCol, articleInColCount, setArticleInColCount }) {
  setArticleInColCount(0)

  const colItemObjects = []
  items.forEach((item, i) => {
    const info = getItemInfo({ item, i, articleInColCount, isLeftCol })
    if (isArticle(item)) {
      articleInColCount++
    }

    colItemObjects.push({ item, info })
  })
  return colItemObjects
}

function getItemInfo({ item, i, articleInColCount, isLeftCol }) {
  // INFO: item logic:
  // per column: articles alternately show and hide their image
  // articles alternately have white and gray background
  // so, since articles alternately show / hide image:
  // - article with image has contextWhite
  // - article without image has contextGray
  // leftCol
  //  - starts with article with image
  //  - text is shown above image
  // rightCol
  //  - starts with article without image
  //  - text is shown below image

  const context = getStyleContext({ item, articleInColCount, isLeftCol })
  const cardComponent = getCardComponent({ item, i, articleInColCount, isLeftCol })
  const layoutClassName = cardComponent === CardWithoutImage ? styles.withoutImageLayout : null

  return {
    layoutClassName,
    context,
    cardComponent,
  }
}

function isArticle(item) {
  return item._type === 'lifeAtRitualsArticle'
}

function isArticleWithImage({ item, articleInColCount, isLeftCol }) {
  return isArticle(item)
    ? isLeftCol
      ? articleInColCount % 2 === 0
      : articleInColCount % 2 === 1
    : false
}

function isImage(item) {
  return item?._type === 'lifeAtRitualsImage'
}

function isVideo(item) {
  return item?._type === 'lifeAtRitualsVideo'
}

function getStyleContext({ item, articleInColCount, isLeftCol }) {
  if (!isArticle(item)) { return cssStyleContext.contextGray }

  if (isLeftCol) {
    return (articleInColCount % 2 === 0
      ? cssStyleContext.contextWhite
      : cssStyleContext.contextGray
    )
  } else {
    return (articleInColCount % 2 === 1
      ? cssStyleContext.contextWhite
      : cssStyleContext.contextGray
    )
  }
}


function getCardComponent({ item, i, articleInColCount, isLeftCol }) {
  if (isArticleWithImage({ item, articleInColCount, isLeftCol })) {
    return isLeftCol ? CardVertical : CardVerticalReversed
  } else {
    return CardWithoutImage
  }
}

function Item({ type, item, side, info = undefined }) {
  const { params: { language } } = useLocationMatch()
  const { __ } = useTranslate()

  return (
    <div
      className={cx(styles.componentItem, side === 'left' ? styles.isLeft : styles.isRight)}
      data-style-context={info?.context}
    >
      {type === 'lifeAtRitualsArticle' && (
        <info.cardComponent
          type={item.category}
          title={item.title}
          label={item?.cardSettings?.cardLabel || __`read-more`}
          description={item?.cardSettings?.cardDescription}
          href={routeMap.app.lifeAtRituals.article({ language, slug: item.slug.current })}
          dataX='link-to-lifeAtRitualsArticle'
          image={item.image}
          layoutClassName={cx(styles.cardLayout, info.layoutClassName)}
          heading={{ h: 2, headingMobile: HeadingSm, headingDesktop: HeadingSm }}
        />
      )}

      {type === 'lifeAtRitualsVideo' && (
        <VideoWithContext
          url={item.video.url}
          poster={item.video.poster}
          trackingTitle={item.video.title}
          title={item.sequenceTitle}
          description={item.videoTitle}
          layoutClassName={styles.videoLayout}
        />
      )}

      {type === 'lifeAtRitualsImage' && (
        <ImageCoverDynamicScale
          image={item.image}
          layoutClassName={styles.imageLayout}
          aspectRatio={1}
        />
      )}
    </div>
  )
}
